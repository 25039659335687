import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Input from "components/dist/atoms/Input";
import Label from "components/dist/atoms/Label";
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import PasswordInput from 'components/dist/molecules/PasswordInput';
import PasswordStrength from "components/dist/molecules/PasswordStrength";
import { FormikHelpers, useFormik } from 'formik';
import React, { useContext } from "react";
import { Route } from "src/constants/routing";
import { AuthContext } from "src/contexts/auth-context";
import { useSubdomainLender } from "src/contexts/subdomain-lender-context";
import { ConfirmForgotPasswordRequest } from "src/types/auth";
import { toast } from "src/utils/toast";
import { FormikAsyncValidateSchema } from "src/utils/yup-validation";

import { CompanyLogo } from "../company-logo";
import { createNewPasswordFormSchema } from "./create-new-password-form.validation";

interface FormValues extends ConfirmForgotPasswordRequest {
    passwordConfirmation: string;
}

interface Props {
    defaultValues?: {
        username?: string;
        confirmationCode?: string;
        confirmationCode2?: string;
    },
    onBack?: () => void;
    showLogo?: boolean;
    showCancelLink?: boolean;
    showBackButton?: boolean;
    onLoginSuccess?: () => void;
}

export const CreateNewPasswordForm = (props: Props) => {
    const { defaultValues = {}, showCancelLink = false, showLogo = true, showBackButton = true, onLoginSuccess } = props;
    const { confirmForgotPassword } = useContext(AuthContext);

    const { logo, name } = useSubdomainLender();
    const formik = useFormik({
        validate: FormikAsyncValidateSchema(createNewPasswordFormSchema),
        validateOnChange: true,
        initialValues: {
            username: '',
            password: '',
            confirmationCode: '',
            confirmationCode2: '',
            passwordConfirmation: '',
            ...defaultValues
        },
        onSubmit: async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
            formikHelpers.setSubmitting(true);
            try {
                const { success, message } = await confirmForgotPassword(values);

                if (success) {
                    onLoginSuccess();
                } else {
                    formikHelpers.setFieldError('passwordConfirmation', message);
                    formikHelpers.setSubmitting(false);
                }
            } catch (error) {
                formikHelpers.setFieldError('passwordConfirmation', error.errors?.message);
                formikHelpers.setSubmitting(false);
            }
        },
    });

    return (
        <form
            className="w-full sm:w-480 mx-auto mt-10 flex flex-col text-left"
            noValidate
            onSubmit={formik.handleSubmit}>
            <div className="gap-6 flex flex-col text-center">
                {showLogo && (<CompanyLogo name={name} logo={logo} />)}
                <Text size="xl" weight="medium">
                    Create new password
                </Text>
            </div>
            <div className="mt-16 w-full flex-col flex gap-2 relative">
                <Label htmlFor="password" variant="secondary" className="text-left w-full" required>New Password</Label>
                <PasswordInput
                    variant="md"
                    wrapClassName="bg-white w-full"
                    id="password"
                    name="password"
                    type="password"
                    data-testid="password"
                    placeholder="Enter Password"
                    autoComplete="new-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password?.length)}
                />
                {formik.values.password && (<Stack className="mt-1 relative">
                    <Stack className=" absolute z-10 sm:w-480 w-full">
                        <PasswordStrength
                            password={formik.values.password}
                        /></Stack>
                </Stack>)}
                {formik.values.password === '' && formik.touched.password && formik.errors.password?.[0] &&
                    <Text as="div" className="flex items-center gap-1" variant="destructive" size="xs">
                        <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                        {formik.errors.password?.[0]}
                    </Text>}
            </div>

            <div className="mt-6 w-full flex-col flex gap-2">
                <Label htmlFor="passwordConfirmation" variant="secondary" className="text-left w-full" required>Confirm New Password</Label>
                <PasswordInput
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    data-testid="passwordConfirmation"
                    placeholder="Enter Password"
                    autoComplete="new-password"
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation?.length)}
                />

                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                    <Text as="div" variant="destructive" size="xs" className="flex items-start gap-1">
                        <div className="mt-0.5">
                            <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                        </div>
                        {Array.isArray(formik.errors.passwordConfirmation) ? formik.errors.passwordConfirmation[0] : formik.errors.passwordConfirmation}
                    </Text>
                )}

                {formik.values.passwordConfirmation && formik.values.passwordConfirmation === formik.values.password && !formik.errors.passwordConfirmation && !formik.isSubmitting &&
                    <Text size="xs" variant="success" className='flex  gap-2'>  <Icon name="Check" className="text-green-400" width={14} height={14} />Passwords match </Text>
                }
            </div>

            <Button
                className="w-full bg-blue-100 hover:bg-blue-60 mt-10"
                data-testid='login-button'
                loading={formik.isSubmitting}
                type='submit' >
                Reset Password
            </Button>

            {showCancelLink && (<Text
                disabled={!formik.values.username}
                className="underline-offset-4 cursor-pointer font-medium text-center mt-8"
                size="sm"
                variant="blue"
                as="a"
                underline
                href={Route.LOGIN}
            >
                Cancel
            </Text>
            )}
        </form>
    )
};


