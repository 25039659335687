import { j as t } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as a, useState as n } from "react";
import "./Accordion-6RJGoeje.js";
import "./Avatar-DNKFm3wh.js";
import "./Badge-QBVLNL8O.js";
import { B as d } from "./Button-BOVHAiPs.js";
import "./DropdownMenu-BmUInubt.js";
import { I as f } from "./Icon-CtH7buGH.js";
import { I as r } from "./Input-CyIITBNc.js";
import "./Label-BtfPjSeJ.js";
import "./Text-B4fIcnEL.js";
import "./Tooltip-ChPGW92B.js";
import "./Stack-BZqOy47V.js";
import "./Switch-D2_ymNuk.js";
import "./Toast-D1jXuM_f.js";
import "./Toggle-BDJtD566.js";
const h = a((s, i) => {
  const [o, m] = n(!1), p = () => {
    m((e) => !e);
  };
  return /* @__PURE__ */ t.jsx(
    r,
    {
      ...s,
      ref: i,
      type: o ? "text" : "password",
      children: /* @__PURE__ */ t.jsx(r.Suffix, { children: /* @__PURE__ */ t.jsx(
        d,
        {
          size: "sm",
          variant: "ghost",
          onClick: p,
          children: /* @__PURE__ */ t.jsx(f, { width: 24, strokeWidth: 1.5, height: 24, name: o ? "EyeEmpty" : "EyeOff" })
        }
      ) })
    }
  );
});
h.displayName = "PasswordInput";
export {
  h as default
};
