
import Box from '@mui/material/Box';
import Separator from "components/dist/atoms/Separator";
import Text from 'components/dist/atoms/Text';
import { FormikHelpers, useFormik } from 'formik';
import React, { useContext, useEffect } from "react";
import VerificationInput from "react-verification-input";
import { AppUserForgotPasswordRequestDto } from "src/backend";
import { BackButton } from "src/components/common/back-button";
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip";
import { AuthContext } from "src/contexts/auth-context";
import { useSubdomainLender } from "src/contexts/subdomain-lender-context";
import { themeV2 } from "src/theme/mysherpas-theme-option";
import { getPrettyCountdownTime } from "src/utils/get-pretty-countdown-time";
import { toast } from "src/utils/toast";
import { FormikAsyncValidateSchema } from "src/utils/yup-validation";
import { useCountdown } from "usehooks-ts";

import { CompanyLogo } from "../company-logo";
import { setNewPasswordFormValidationSchema } from "./set-new-password-form-validation";

interface FormValues extends AppUserForgotPasswordRequestDto { }

interface Props {
    defaultValues?: {
        username?: string;
        confirmationCode?: string;
    },
    onBack?: () => void;
    showLogo?: boolean;
    showBackButton?: boolean;
    onVerifySuccess?: (args: { confirmationCode: string, confirmationCode2: string }) => void;
    isVerificationCodeSent?: boolean;
}

const SetNewPasswordForm = (props: Props) => {
    const { defaultValues = {}, showLogo = true, showBackButton = true, onVerifySuccess } = props;
    const [isSendingVerificationCode, setIsSendingVerificationCode] = React.useState(false);
    const [isCodeValid, setIsCodeValid] = React.useState(false);
    const { forgotPassword, verifyConfirmationCode } = useContext(AuthContext);
    const [count, { startCountdown, resetCountdown }] = useCountdown({
        countStart: 600,
        intervalMs: 1000,
    })
    const { logo, name } = useSubdomainLender();
    const formik = useFormik({
        validate: FormikAsyncValidateSchema(setNewPasswordFormValidationSchema),
        initialValues: {
            lenderUniqueId: '',
            username: '',
            confirmationCode: '',
            confirmationCode2: '',
            provider: '',
            url: '',
            password: '',
            packageInfoId: '',
            loanId: '',
            messageThreadId: '',
            ...defaultValues
        },
        onSubmit: async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
            formikHelpers.setSubmitting(true);
            try {
                const response = await verifyConfirmationCode(values);
                if (response.confirmed) {
                    setIsCodeValid(true);
                    onVerifySuccess?.({
                        confirmationCode: values.confirmationCode,
                        confirmationCode2: response.confirmationCode2
                    });
                } else {
                    throw new Error('The verification code is incorrect');
                }
            } catch (error) {
                formik.setFieldError('confirmationCode', error.message);
                formikHelpers.setSubmitting(false);
            }
        },
    });

    React.useEffect(() => {
        if (count === 0) {
            formik.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, formik.resetForm]);

    React.useEffect(() => {
        if (!props.isVerificationCodeSent) {
            handleResendCodeClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVerificationCodeSent]);

    const handleResendCodeClick = async () => {
        setIsSendingVerificationCode(true);
        const lResponse = await forgotPassword({
            username: formik.values.username
        });
        if (lResponse.success) {
            toast({
                type: 'success',
                content: 'Email sent to ' + formik.values.username + '. If you do not receive an email, please check your spelling and try again.',
            });
            resetCountdown();
            startCountdown();
        } else {
            toast({
                type: 'info',
                content: lResponse.message
            })
        }
        setIsSendingVerificationCode(false);

        formik.resetForm();
    }
    const handleVerificationCodeChange = (value: string) => {
        if (value.length === 6) {
            formik.setFieldValue('confirmationCode', value, true).then(() => {
                formik.handleSubmit();
            });
        } else {
            formik.setFieldValue('confirmationCode', value);
        }
    }

    useEffect(() => {
        startCountdown()
    }, [startCountdown])

    // replace first/last 3 characters of username with asterisks
    const usernameMasked = formik.values.username.replace(/^(.{3})(.*)(.{3})$/, '$1***$3');
    const confirmationCodeError = formik.values.confirmationCode?.length === 6 && formik.errors.confirmationCode;

    return (
        <form onSubmit={formik.handleSubmit} className="sm:w-480 text-center px-5 sm:px-0">
            {showBackButton && (
                <Box sx={{
                    position: 'fixed',
                    top: {
                        xs: 16,
                        sm: 32,
                        md: 80
                    },
                    left: {
                        xs: 16,
                        sm: 32,
                        md: 64
                    },
                    zIndex: 2
                }}>
                    <BackButton onClick={props.onBack} />
                </Box>
            )}
            <div className="gap-6 flex flex-col mt-10 pt-10">
                {showLogo && (<CompanyLogo name={name} logo={logo} />)}
                <Text size="xl" weight="medium">
                    Verify your email
                </Text>
            </div>
            <Text className="mt-3" size="sm">
                Before resetting your password, please enter the verification code sent to the email {usernameMasked}
            </Text>
            <Box
                sx={{
                    mt: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    '& .verification-input-container': {
                        width: 'auto'
                    },
                    '& .verification-input-character': {
                        width: 48,
                        height: 48,
                        borderRadius: themeV2.shape.borderRadius.input,
                        borderColor: (isCodeValid ? themeV2.colors.green[60] : themeV2.neutral.grey[80]),
                        backgroundColor: 'white',
                        flexGrow: 'unset',
                        flexBasis: 'unset',
                        fontSize: 16,
                        lineHeight: '48px',
                        ...(confirmationCodeError && {
                            borderColor: themeV2.colors.red[50],
                        })
                    },
                    '& .verification-input-character-selected': {
                        outlineStyle: 'solid',
                        outlineWidth: 1,
                        outlineColor: themeV2.colors.blue[100],
                        borderColor: themeV2.colors.blue[100],
                        color: themeV2.colors.blue[100],
                    }
                }}>
                <div className="flex flex-col">
                    <VerificationInput
                        autoFocus
                        length={6}
                        placeholder='-'
                        onChange={handleVerificationCodeChange}
                        value={formik.values.confirmationCode ? formik.values.confirmationCode : ''}
                        classNames={{
                            container: "verification-input-container",
                            character: "verification-input-character",
                            characterSelected: "verification-input-character-selected",
                        }} />
                </div>
            </Box>
            {count > 0 && <Text center size="sm" className="pt-8">
                Code expires in <Text className="font-medium" as="span" variant="blue" size="sm" fontWeight="medium">
                    {getPrettyCountdownTime(count)}
                </Text>
            </Text>}

            {count === 0 && <Text size="sm" className="pt-8 text-destructive items-center text-center w-full">
                Code expired. Click Resend Code to get a new code
            </Text>}

            {count > 0 && formik.values.confirmationCode?.length === 6 && formik.errors.confirmationCode && <Text variant="destructive" size="sm" className="pt-2 w-full items-center  text-center">
                {formik.errors.confirmationCode}
            </Text>}

            <Separator className="mb-6 mt-6" />
            <div className="text-center pt-6">
                <MysherpasTooltip title={!formik.values.username ? 'Enter username to request new code' : 'Request a new code'}>
                    <Text
                        disabled={!formik.values.username || isSendingVerificationCode}
                        className="underline underline-offset-4 cursor-pointer font-medium"
                        size="sm"
                        variant="blue"
                        onClick={handleResendCodeClick}
                    >
                        Resend code
                    </Text>
                </MysherpasTooltip>
            </div>
        </form>
    )
};

export default SetNewPasswordForm;

