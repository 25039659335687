import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import RequestPasswordResetForm from 'src/components/auth/tmp-request-password-reset-form/request-password-reset-form';
import SetNewPasswordForm from 'src/components/auth/tmp-set-new-password-form/set-new-password-form';
import { Route } from "src/constants/routing";
import { ForgotPasswordRequest } from 'src/types/auth';
import { decodeBase64 } from 'src/utils/decode-base64';
import { isValidEmail } from 'src/utils/validation';

import { CreateNewPasswordForm } from './create-new-password-form';

type Props = {
  showLogo?: boolean;
  showCancelLink?: boolean;
  showBackButton?: boolean;
  onLoginSuccess?: () => void;
};

const ForgotPasswordPage: FC<Props> = ({
  showLogo = true,
  showCancelLink = true,
  showBackButton = true,
  onLoginSuccess,
}) => {
  const [signUpData, setSignUpData] = useState<{
    username: string | null;
    confirmationCode: string | null;
    confirmationCode2?: string | null;
  }>({
    username: null,
    confirmationCode: null,
  });
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(true);
  const {
    isReady,
    query: { email, code },
    push,
  } = useRouter();

  const handleGoBack = () => {
    push({
      pathname: Route.LOGIN,
    });
  };

  const handleUsernameSuccess = (values: ForgotPasswordRequest) => {
    setSignUpData(prevState => ({
      ...prevState,
      username: values.username,
    }));
  };

  const handleVerifySuccess = (args: { confirmationCode: string, confirmationCode2: string }) => {
    setSignUpData(prevState => ({
      ...prevState,
      confirmationCode: args.confirmationCode,
      confirmationCode2: args.confirmationCode2,
    }));
  };

  const handleBackButton = () => {
    setSignUpData(prevState => ({
      ...prevState,
      username: null,
      confirmationCode: null
    }));

    if (!!email) {
      push({
        pathname: Route.LOGIN,
      });
    }
  };

  useEffect(() => {
    if (isReady) {
      if (email && isValidEmail(decodeBase64(String(email)))) {
        setSignUpData(prevState => ({
          ...prevState,
          username: decodeBase64(String(email))
        }));
        setIsVerificationCodeSent(false)
      }
      if (code) {
        setSignUpData(prevState => ({
          ...prevState,
          confirmationCode: String(code)
        }));
      }
    }
  }, [code, email, isReady]);


  if (signUpData.confirmationCode) {
    return (
      <CreateNewPasswordForm
        showLogo={showLogo}
        showBackButton={showBackButton}
        onLoginSuccess={onLoginSuccess}
        showCancelLink
        onBack={() => {
          setSignUpData(prevState => ({
            ...prevState,
            username: null,
            confirmationCode: null
          }));
        }}
        defaultValues={{
          username: signUpData.username,
          confirmationCode: signUpData.confirmationCode,
          confirmationCode2: signUpData.confirmationCode2,
        }}
      />
    );
  }

  if (signUpData.username) {
    return (
      <SetNewPasswordForm
        showLogo={showLogo}
        showBackButton={showBackButton}
        onVerifySuccess={handleVerifySuccess}
        onBack={handleBackButton}
        defaultValues={{
          username: signUpData.username ? signUpData.username : String(email),
          confirmationCode: signUpData.confirmationCode,
        }}
        isVerificationCodeSent={isVerificationCodeSent}
      />
    );
  }

  return (
    <RequestPasswordResetForm
      onBack={handleGoBack}
      onSuccess={handleUsernameSuccess}
      showLogo={showLogo}
      showCancelLink={showCancelLink}
    />
  );
};

export default ForgotPasswordPage;